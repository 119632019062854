import React from "react";
import { DragDropContext, DropTarget, DragSource } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import ProgressBar from "./progressBar";

const tasks = [
  ...[...Array(1000)].map((_, index) => ({
    _id: index,
    title: `JOEL DE LIMA LTDA ${index}`,
    status: "backlog",
  })),
  ...[...Array(2000)].map((_, index) => ({
    _id: (index + 1000),
    title: `JOEL DE LIMA LTDA ${(index + 1000)}`,
    status: "new",
  })),
  ...[...Array(2000)].map((_, index) => ({
    _id: (index + 3000),
    title: `JOEL DE LIMA LTDA ${(index + 3000)}`,
    status: "review",
  })),
  ...[...Array(2000)].map((_, index) => ({
    _id: (index + 5000),
    title: `JOEL DE LIMA LTDA ${(index + 6000)}`,
    status: "done",
  })),
];

const channels = ["backlog", "new", "wip", "review", "done"];
const labelsMap = {
  backlog: "TAREFAS FUTURAS",
  new: "TAREFAS PARA HOJE",
  wip: "TAREFAS EM ATRASO",
  review: "TAREFAS EM EXECUÇÃO",
  done: "TAREFAS REALIZADAS",
};

const classes = {
  board: {
    display: "flex",
    margin: "0 auto",
    width: "90vw",
    maxHeight: "400px",
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
    backgroundColor: "red",
  },
  column: {
    minWidth: 150,
    width: "18vw",
    height: "80vh",
    margin: "0 auto",
    backgroundColor: "#000",
  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    fontSize: "1em",
    backgroundColor: "yellow",
    border: '2px solid #000',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: 9,
    border: '2px solid yellow',
  },
  itemTitle: {
    marginTop: 5,
    width: '100%',
    textAlign: 'left',
    fontSize: "1.1em",
    fontWeight: '600',
    borderBottom: '1px solid #000',
  },
  itemTitleGreen: {
    fontSize: "1.1em",
    fontWeight: '600',
    color: 'rgb(15, 162, 152)',
  },
  itemTitleBlue: {
    marginTop: 2,
    fontSize: "1em",
    fontWeight: '600',
    color: 'rgb(21, 84, 178)',
  },
  itemDataHora: {
    margin: 10,
    padding: 4,
    fontSize: "1em",
    fontWeight: '600',
    color: '#faad14',
    border: '1px solid #faad14',
    borderRadius: '5px',
  }
};

class Kanban extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks,
    };
  }
  update = (id, status) => {
    const { tasks } = this.state;
    const task = tasks.find((task) => task._id === id);
    task.status = status;
    const taskIndex = tasks.indexOf(task);
    const newTasks = update(tasks, {
      [taskIndex]: { $set: task },
    });
    this.setState({ tasks: newTasks });
  };

  render() {
    const { tasks } = this.state;
    return (
      <main>
        <section style={classes.board}>
          {channels.map((channel) => (
            <KanbanColumn status={channel}>
              <div style={classes.column}>
                <div style={classes.columnHead}>{labelsMap[channel]}</div>
                <div style={{ height: "70vh", overflowX: "auto" }}>
                  {tasks
                    .filter((item) => item.status === channel)
                    .map((item) => (
                      <KanbanItem id={item._id} onDrop={this.update}>
                        <div style={classes.item}>
                          <div style={{ width: "250px" }}>
                            <ProgressBar valuePercentage={80} />
                          </div>
                          <div style={classes.itemTitleGreen}>{item.title}</div>
                          <div style={classes.itemDataHora}>{'out 17'}</div>
                          <div style={classes.itemTitle}>{'Contato'}</div>
                          <div style={classes.itemTitleBlue}>{item.title}</div>
                          <div style={classes.itemTitle}>{'Vendedor'}</div>
                          <div style={classes.itemTitleBlue}>{item.title}</div>
                        </div>
                      </KanbanItem>
                    ))}
                </div>
              </div>
            </KanbanColumn>
          ))}
        </section>
      </main>
    );
  }
}

export default DragDropContext(HTML5Backend)(Kanban);

// Column

const boxTarget = {
  drop(props) {
    return { name: props.status };
  },
};

class KanbanColumn extends React.Component {
  render() {
    return this.props.connectDropTarget(<div>{this.props.children}</div>);
  }
}

KanbanColumn = DropTarget("kanbanItem", boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(KanbanColumn);

// Item

const boxSource = {
  beginDrag(props) {
    return {
      name: props.id,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDrop(monitor.getItem().name, dropResult.name);
    }
  },
};

class KanbanItem extends React.Component {
  render() {
    return this.props.connectDragSource(<div>{this.props.children}</div>);
  }
}

KanbanItem = DragSource("kanbanItem", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(KanbanItem);
